<template>
  <main>
    <headerElem></headerElem>
    <div class="done">
      <h2>Merci d’avoir joué !</h2>
      <hr />
      <p>
        <b
          >Vous participez ainsi au tirage au sort pour gagner peut-être l’un
          des lots mis en jeu dans votre entreprise :</b
        >
        <br /><br />
        40€ de produits gastronomiques italiens ou 1 Machine à café BODUM ou 1
        Mug LAVAZZA réutilisable
        <br /><br />
        <b>Bon café !</b>
      </p>
    </div>
  </main>
</template>
<script>
import HeaderElem from "@/components/Header";
export default {
  name: "lz-done",
  components: {
    HeaderElem,
  },
};
</script>

<style lang="scss">
.done {
  width: 96%;
  margin: 0 auto;
  color: white;
  text-align: center;
  margin-top: 120px;
  @media screen and (max-width: 768px) {
    margin-top: 0px;
  }
  h2 {
    font-family: "Gotham";
    font-size: 20px;
    line-height: 25px;
  }

  hr {
    width: 174px;
    border: 1px solid white;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  p {
    max-width: 290px;
    margin: 0 auto;
    font-size: 17px;
  }
}
</style>
